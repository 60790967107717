<template>
  <section class="custom-width d-flex flex-column px-3">
    <table :id="`${tableName}`" style="table-layout: auto">
      <thead>
        <tr>
          <th class="border px-4 py-3 d-flex align-items-center">
            <div
              v-if="tableName !== 'plans'"
              class="min-w-20px"
              style="flex-basis: 10%"
            >
              <div
                class="float-left form-check form-check-sm form-check-custom form-check-solid min-w-20px min-h-20px"
              >
                <input
                  :disabled="!isPreviousOptionSelected"
                  class="form-check-input"
                  type="checkbox"
                  :checked="!selectAllComputedValue"
                  @click="(event) => toggleSelectAll(event.target.checked)"
                />
              </div>
            </div>
            <span
              class="text-center text-capitalize font-weight-bolder font-size-h5"
              style="flex-basis: 80%"
            >
              {{ tableName }}</span
            >
            <button
              v-if="tableName === 'plans'"
              @click="() => handleCreatePlan()"
              class="float-right pa-0 btn btn-icon-info ms-8 ms-lg-16"
            >
              <span class="svg-icon ma-0">
                <v-icon size="24">mdi-plus-box-outline</v-icon>
              </span>
            </button>
          </th>
        </tr>
        <tr>
          <th class="border px-4 py-2">
            <input
              :id="`search-${tableName}`"
              type="search"
              class="border custom-input rounded w-100 py-2 px-3 text-dark-75"
              @keyup="filterInputs"
              :placeholder="`Search ${tableName}..`"
            />
          </th>
        </tr>
      </thead>
      <tbody :class="`${tableName}-tb`">
        <template v-if="items.length === 0">
          <tr>
            <td class="border px-4 py-2">{{ label }}</td>
          </tr>
        </template>
        <template v-else>
          <div class="list-container">
            <tr
              v-for="(item, index) in items"
              :key="item.id"
              class="d-block w-100"
            >
              <td
                class="border px-4 py-2 d-flex justify-space-between align-center"
              >
                <div class="d-flex align-center">
                  <template v-if="tableName === 'plans'">
                    <span class="font-size-lg py-1 font-weight-medium ml-2">
                      {{ item.name }}
                      <span
                        v-if="item.is_active"
                        class="d-inline-block bg-gray-200 rounded-lg ml-2 px-3 text-dark-65"
                      >
                        Enabled
                      </span>
                    </span>
                  </template>

                  <!--                  <template v-else-if="tableName === 'chutes'">-->
                  <template v-else>
                    <div
                      class="form-check form-check-sm form-check-custom form-check-solid me-2 min-w-20px min-h-20px"
                    >
                      <input
                        :disabled="!isPreviousOptionSelected"
                        class="form-check-input"
                        type="checkbox"
                        v-model="item.is_selected"
                        @click="
                          (event) => toggleSelect(event.target.checked, item)
                        "
                      />
                    </div>
                    <span class="font-size-lg py-1 font-weight-medium ml-2">
                      {{ item.name }}
                    </span>
                  </template>
                </div>
                <div class="d-flex align-center justify-end">
                  <ActionsMenu
                    v-if="tableName === 'plans'"
                    :item="item"
                    @handleEditPlan="(plan) => handleCreatePlan(plan)"
                  />

                  <button
                    v-if="tableName !== 'categories'"
                    :disabled="item?.is_selected === false"
                    @click="(event) => handleNextPress(event, item, index)"
                    class="btn btn-sm btn-icon btn-circle btn-bg-light btn-hover-light-info"
                    :class="`${tableName}-tb-${item.id}-next-btn`"
                  >
                    <span class="svg-icon">
                      <v-icon size="19"> mdi-arrow-right </v-icon>
                    </span>
                  </button>
                </div>
              </td>
            </tr>
          </div>
        </template>
      </tbody>
    </table>
  </section>
</template>

<script>
import ActionsMenu from "@/own/components/warehouseExecutionSystem/sortPlan/ActionsMenu.vue";
import {
  ADD_REMOVE_CHUTE_TO_SELECTED_PLAN,
  ADD_REMOVE_LOCATION_TO_SELECTED_CHUTE,
  ADD_REMOVE_CATEGORY_TO_SELECTED_LOCATION,
  SELECT_ALL_CHUTES,
  SELECT_ALL_LOCATIONS,
  SELECT_ALL_CATEGORIES,
} from "@/core/services/store/wesSortPlan.module";

export default {
  name: "NestedDataTable",
  components: { ActionsMenu },
  props: {
    tableName: {
      type: String,
      required: true,
    },
    handleGetFunction: {
      type: Function,
      required: false,
    },
    handleCreatePlan: {
      type: Function,
      required: false,
    },
  },
  computed: {
    planDetails: function () {
      return this.$store.getters.getWESSORTPLANSelectedPlanDetails;
    },
    items: function () {
      if (this.tableName === "plans")
        return this.$store.getters.getWESSORTPLANPlans;
      else if (this.tableName === "chutes")
        return this.$store.getters.getWESSORTPLANChutes;
      else if (this.tableName === "locations")
        return this.$store.getters.getWESSORTPLANLocations;
      else return this.$store.getters.getWESSORTPLANCategories;
    },
    isPreviousOptionSelected: function () {
      if (this.tableName === "chutes") return this.planDetails.plan_index > -1;
      else if (this.tableName === "locations")
        return this.planDetails.chute_index > -1;
      else if (this.tableName === "categories")
        return this.planDetails.location_index > -1;
      else return false;
    },
    label: function () {
      return "Select item";
      // const {
      //   planLabel,
      //   chuteLabel,
      //   locationLabel,
      //   categoryLabel,
      // } = this.$store.getters.getWESSORTPLANLabels;
      // if (this.tableName === "plans") return planLabel;
      // else if (this.tableName === "chutes") return chuteLabel;
      // else if (this.tableName === "locations") return locationLabel;
      // else return categoryLabel;
    },
    selectAllComputedValue: function() {
      const index = this.items.findIndex((item) => item.is_selected === false);
      return index > -1;
    },
  },
  methods: {
    toggleSelectAll(isChecked) {
      if (this.tableName === "chutes") {
        this.$store.commit(SELECT_ALL_CHUTES, isChecked);

        this.$store.getters.getWESSORTPLANLocations.forEach((location) => {
          location.is_selected = false;
        });

        this.$store.getters.getWESSORTPLANCategories.forEach((category) => {
          category.is_selected = false;
        });
      } else if (this.tableName === "locations") {
        this.$store.commit(SELECT_ALL_LOCATIONS, isChecked);

        this.$store.getters.getWESSORTPLANCategories.forEach((category) => {
          category.is_selected = false;
        });
      } else if (this.tableName === "categories") {
        this.$store.commit(SELECT_ALL_CATEGORIES, isChecked);
      }
    },
    toggleSelect(isChecked, selectedField) {
      if (this.tableName === "chutes") {
        this.$store.commit(ADD_REMOVE_CHUTE_TO_SELECTED_PLAN, {
          selectedField,
          forAdding: isChecked,
        });
      } else if (this.tableName === "locations") {
        this.$store.commit(ADD_REMOVE_LOCATION_TO_SELECTED_CHUTE, {
          selectedField,
          forAdding: isChecked,
        });
      } else if (this.tableName === "categories") {
        this.$store.commit(ADD_REMOVE_CATEGORY_TO_SELECTED_LOCATION, {
          selectedField,
          forAdding: isChecked,
        });
      }
    },
    handleNextPress(event, item, index) {
      if (this.tableName === "plans") {
        this.handleGetFunction(item, event, this.tableName, index);
      } else if (this.tableName === "chutes") {
        this.handleGetFunction(
          item,
          event,
          this.tableName,
          this.planDetails.plan_index
        );
      } else if (this.tableName === "locations") {
        this.handleGetFunction(
          item,
          event,
          this.tableName,
          this.planDetails.plan_index,
          this.planDetails.chute_index
        );
      }
    },
    filterInputs() {
      let input, filter, table, tr, td, i, txtValue;
      input = document.getElementById("search-" + this.tableName);
      filter = input.value.toUpperCase();
      table = document.getElementById(this.tableName);
      tr = table.getElementsByTagName("tr");

      // Loop through all table rows, and hide those who don't match the search query
      for (i = 0; i < tr.length; i++) {
        td = tr[i].getElementsByTagName("td")[0];
        if (td) {
          txtValue = td.textContent || td.innerText;
          if (txtValue.toUpperCase().indexOf(filter) === -1) {
            tr[i].classList.replace("d-block", "d-none");
          } else {
            tr[i].classList.replace("d-none", "d-block");
          }
        }
      }
    },
  },
};
</script>

<style scoped>
td:hover {
  background: #fafafa;
}

.custom-input {
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
  outline: none;
  font-weight: normal;
}

.custom-input:focus {
  box-shadow: 0 0 0 3px rgb(66 153 225 / 50%);
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.custom-width {
  width: 100%;
}

@media (min-width: 769px) {
  .custom-width {
    width: 25%;
  }
}

.list-container {
  overflow-y: scroll;
  max-height: 36vh !important;
}

@media (min-width: 1550px) {
  .list-container {
    max-height: 42vh !important;
  }
}

@media (min-width: 1800px) {
  .list-container {
    max-height: 48vh !important;
  }
}

@media (min-width: 2000px) {
  .list-container {
    max-height: 52vh !important;
  }
}

@media (min-width: 2200px) {
  .list-container {
    max-height: 56vh !important;
  }
}
</style>
